import { IUser } from "../models/IUser"
import { makeAutoObservable } from "mobx"
import AuthService from "../services/AuthService"
import axios from 'axios';
import { AuthResponse } from '../models/response/AuthResponse';
import { API_URL } from "../http";
import {baseStore} from "./BaseStore";

export default class AuthStore{

    user = {} as IUser
    isAuth = localStorage.getItem('token') && localStorage.getItem('refreshToken') ? true : false
    isLoading = false

    role = 3
    showError = false
    showErrorMessage = ""

    constructor() {
        makeAutoObservable(this)
    }

    setAuth(bool: boolean) {
        this.isAuth = bool
    }

    setRole(num: number) {
        this.role = num
    }

    setShowError(bool: boolean) {
        this.showError = bool
    }

    setShowErrorMessage(str: string) {
        this.showErrorMessage = str
    }

    setUser(user: IUser) {
        this.user = user
    }

    setLoading(bool: boolean) {
        this.isLoading = bool
    }

    clearTokens() {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
    }

    clearError() {
        this.setShowError(false)
        this.setShowErrorMessage("")
    }

    async login(email: string, password: string) {
        try {
            this.clearTokens()
            const response = await AuthService.login(email, password)
            if (response.data.accessToken) {
                localStorage.setItem('token', response.data.accessToken)
                localStorage.setItem('refreshToken', response.data.refreshToken)
                this.setAuth(true)
                this.setUser(response.data.user)
                this.setRole(response.data.user.role)
            } else {
                baseStore.addError(response.data.error ?? "")
            }

        } catch (e) {
            baseStore.addError(JSON.stringify(e, undefined, 2))
            this.setAuth(false)
            this.setUser({} as IUser)
            this.setRole(3)
            console.log(e)
        }

    }

    async registration(firstName: string, lastName: string, email: string, password: string, passwordConfirmation: string) {
        try {
            const response = await AuthService.registration(firstName, lastName, email, password, passwordConfirmation)
            localStorage.setItem('token', response.data.accessToken)
            localStorage.setItem('refreshToken', response.data.refreshToken)
            this.setAuth(true)
            this.setUser(response.data.user)
        } catch (e) {
            baseStore.addError(JSON.stringify(e, undefined, 2))
            console.log(e)
        }
    }

    async logout() {
        try {
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            this.setAuth(false)
            this.setUser({} as IUser)
            this.setRole(3)
        } catch (error) {
            baseStore.addError(JSON.stringify(error, undefined, 2))
            console.log(error)
        }
    }

    async checkAuth() {
        this.setLoading(true)
        try {
            const response = await axios.post<AuthResponse>(
                `${API_URL}/sessions/refresh`,
                { refreshToken: localStorage.getItem('refreshToken') },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
                    }
                })
            this.setAuth(true)
            this.setUser(response.data.user)
            this.setRole(response.data.user.role)
        } catch (error) {
            this.setAuth(false)
            this.setUser({} as IUser)
            this.setRole(3)
            baseStore.addError(JSON.stringify(error, undefined, 2))
            console.log(error)
        } finally {
            this.setLoading(false)
        }
    }
}