import { IActor } from '../models/IActor';
import { makeAutoObservable } from "mobx"
import ActorService from "../services/ActorService"
import ImageService from '../services/ImageService';
import IUploaded from '../models/IUploaded';
import { baseStore } from "./BaseStore";
export default class ActorStore {

    isLoading = false
    isLoadingPhotos = false
    actors = [] as IActor[]
    count = 0
    pages = 0
    actor = {
        patronymic: '',
        firstName: '',
        lastName: '',
        gender: 0,
        birthDay: '',
        age: undefined,
        race: 0,
        citizenship: 20,
        city: '',
        avatar: '',
        theater: '',
        education: 0,
        constitution: 0,
        eyeColor: 0,
        hairColor: 0,
        height: 0,
        weight: 0,
        skills: [],
        filmography: [],
        media: [],
        photos: [],
        videos: []
    } as IActor

    showError = false
    showErrorMessage = ""

    constructor() {
        makeAutoObservable(this)
    }

    setShowError(bool: boolean) {
        this.showError = bool
    }

    setShowErrorMessage(str: string) {
        this.showErrorMessage = str
    }

    setLoading(bool: boolean) {
        this.isLoading = bool
    }

    setCount(count: number) {
        this.count = count
    }

    setPages(pages: number) {
        this.pages = pages
    }

    setLoadingPhotos(bool: boolean) {
        this.isLoadingPhotos = bool
    }

    setActors(actors: IActor[]) {
        this.actors = actors
    }

    setActor(actor: IActor) {
        this.actor = actor
    }

    changeAvatar(avatar: string) {
        this.actor.avatar = avatar
    }

    clearActor() {
        this.setActor({
            patronymic: '',
            firstName: '',
            lastName: '',
            gender: 0,
            birthDay: '',
            age: undefined,
            race: 0,
            citizenship: 20,
            city: '',
            avatar: '',
            theater: '',
            education: 0,
            constitution: 0,
            eyeColor: 0,
            hairColor: 0,
            height: 0,
            weight: 0,
            skills: [],
            filmography: [],
            media: [],
            photos: [],
            videos: []
        } as IActor)
    }


    async getActors(page: string, limit: string, filter: string  = '') {
        this.setLoading(true)
        try {
            const response = await ActorService.fetchActors(page, limit, filter)
            const { actors, count, pages } = response.data
            this.clearActor()
            this.setActors(actors)
            this.setCount(count)
            this.setPages(pages)
        } catch (error) {
            this.setActors([])
            console.log(error)
            baseStore.addError(JSON.stringify(error, undefined, 2))
        } finally {
            this.setLoading(false)
        }
    }

    async getActor(id: string) {
        this.setLoading(true)
        try {
            const response = await ActorService.fetchActor(id)
            this.setActor(response.data)
            return this.actor
        } catch (error) {
            console.log(error)
            baseStore.addError(JSON.stringify(error, undefined, 2))
        } finally {
            this.setLoading(false)
        }
        return {} as IActor
    }

    async save() {
        this.setLoading(true)
        try {
            const response = await ActorService.upadateActor(this.actor)
            this.setActor(response.data)
        } catch (error) {
            console.log(error)
            baseStore.addError(JSON.stringify(error, undefined, 2))
        } finally {
            this.setLoading(false)
            return this.actor._id ?? ''
        }
    }

    async delete(id: string) {
        this.setLoading(true)
        try {
            this.setShowError(false)
            this.setShowErrorMessage("")
            const response = await ActorService.deleteActor(id)
            console.log('delete', response.data)
            if (response.data?.error) {
                this.setShowError(true)
                this.setShowErrorMessage(response.data.error)
            }
            this.clearActor()
        } catch (error) {
            console.log(error)
            baseStore.addError(JSON.stringify(error, undefined, 2))
        } finally {
            this.setLoading(false)
        }
    }

    async upload(files: File[]): Promise<IUploaded[]> {
        this.setLoadingPhotos(true)
        let uploaded: IUploaded[] = []
        try {
            const response = await ImageService.upload(files)
            uploaded = response.data
        } catch (error) {
            console.log(error)
            baseStore.addError(JSON.stringify(error, undefined, 2))
        } finally {
            this.setLoadingPhotos(false)
            return uploaded
        }
    }

    async deletePhoto(name: string) {

    }

}