import { FC, useRef } from "react"

import { Button, Container, Figure, Modal, Stack, OverlayTrigger, Pagination, Spinner, Table, Alert, Form, Row, Col } from "react-bootstrap"

import { useContext, useState, useEffect } from "react"
import { Context } from "../index"
import { IActor } from '../models/IActor';
import { observer } from "mobx-react-lite";
import { useParams, Link, useHistory } from 'react-router-dom';
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";

const Index: FC = () => {
    const { page = '1' } = useParams<{ page: string }>()
    const { limit = '10' } = useParams<{ limit: string }>()
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const { actorStore } = useContext(Context)
    const [show, setShow] = useState<boolean>(false)
    const [toDelete, setToDelete] = useState<string>('')

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [fio, setFio] = useState('');

    const history = useHistory()

    useEffect(() => {
        document.title = "Актеры"
        actorStore.getActors(page, limit)
        inputRef.current?.focus()

    }, [actorStore, limit, page])

    useEffect(() => {
        document.title = "Актеры"

        if (!fio.length && inputRef.current) {
            actorStore.getActors(page, limit)
        }
    }, [fio])


    if (actorStore.isLoading) {
        return (
            <Container>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        )
    }
    const getFullName = (firstname?: string, lastname?: string, patronymic?: string) => {
        return [firstname, lastname, patronymic].join(' ')
    }

    const deleteActor = async (id: string | undefined) => {
        if (id) {
            await actorStore.delete(id)
            if (!actorStore.showError) {
                await actorStore.getActors(page, limit)
                if (actorStore.actors.length === 0) {
                    let prevPage = parseInt(page) - 1
                    history.push(`/actors/${prevPage.toString()}/${limit}`)
                }
            } else {
                setShowAlert(true)
            }

        }
    }

    const makeTr = (actor: IActor, idx: number) => {
        return (
            <tr key={actor._id} style={{ cursor: 'pointer' }}>

                <td>{parseInt(limit) * (parseInt(page) - 1) + idx + 1}</td>
                <td>
                    <Link to={`/actor/${actor._id}?page=${page}&limit=${limit}`}>
                        {getFullName(actor.lastName, actor.firstName)}
                    </Link>
                </td>
                <td style={{ textAlign: 'center' }}>
                    <OverlayTrigger key="left" placement="left" overlay={<Figure.Image width={271} height={280} alt="avatar" src={actor.avatar} />}>
                        <Button variant="danger" onClick={() => { handleShow(actor._id) }}> Удалить </Button>
                    </OverlayTrigger>
                </td>
            </tr >
        )
    }

    const handleShow = (id: string | undefined) => {
        if (id) {
            setShow(true)
            setToDelete(id)
        }
    }

    const handleCloseReset = () => {
        setShow(false)
    }

    const handleCloseConfirm = async () => {
        await deleteActor(toDelete)
        setShow(false)

    }

    const confirmDelete = () => {
        return (
            <Modal show={show} onHide={handleCloseReset}>
                <Modal.Header closeButton>
                    <Modal.Title>Подтвердите удаление актера</Modal.Title>
                </Modal.Header>
                <Modal.Body>Вы хотите удалить актера?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReset}>
                        Отмена
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirm}>Удалить</Button>

                </Modal.Footer>
            </Modal>
        )
    }

    const toPageHandler = (number: number) => {
        history.push(`/actors/${number}/${limit}`)
    }


    const paginator = () => {
        let items = []
        let end = actorStore.pages
        let current = parseInt(page)
        const [minP, maxP] = [current > 5 ? current - 5 : 1, current > 5 ? current + 5 : 10]
        items.push(
            <Pagination.Item
                key="first"
                hidden={current <= 6}
                onClick={() => toPageHandler(1)}
            >
                <IoIosSkipBackward />
            </Pagination.Item>
        )
        for (let number = minP; number <= maxP && number < end; number++) {
            items.push(
                <Pagination.Item
                    disabled={actorStore.count > ((number - 1) * parseInt(limit)) ? false : true}
                    key={number}
                    active={number === current}
                    onClick={() => toPageHandler(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }
        items.push(
            <Pagination.Item
                key="last"
                // hidden={current <= 6}
                onClick={() => toPageHandler(end - 1)}
            >
                <IoIosSkipForward />
            </Pagination.Item>
        )
        return (
            <>
                <Pagination style={{ marginBottom: 0 }}>
                    {items}
                </Pagination>
            </>
        )
    }

    const AlertDismissibleExample = () => {
        if (showAlert) {
            return (
                <Alert transition={true} variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    <Alert.Heading>Ой! Что-то пошло не так</Alert.Heading>
                    <p>
                        {actorStore.showErrorMessage}
                    </p>
                </Alert>
            );
        }
        return <></>
    }

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFio(event.currentTarget.value)
    }

    const handleSearch = () => {
        actorStore.getActors('1', limit, fio)
    }

    return (
        <Container>
            {AlertDismissibleExample()}
            <Row>
                <Col xs={10}>
                    <Form.Control
                        ref={inputRef}
                        placeholder="Введите ФИО актера"
                        type="text"
                        value={fio}
                        onInput={handleInput}
                    />
                </Col>
                <Col xs={2}>
                    <Button style={{ width: '100%' }} variant="primary" onClick={handleSearch}>Поиск</Button>
                </Col>
            </Row>
            <br />
            <Stack direction="horizontal" gap={3} style={{ marginBottom: 10 }}>
                {paginator()}
                <Button variant="success" onClick={(e) => history.push('/actor')} className="ms-auto"> Добавить нового актера </Button>
            </Stack>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th colSpan={2}>ФИО <small>(нажмите на имя, для редактирования актера)</small></th>
                    </tr>
                </thead>
                <tbody>
                    {actorStore.actors.map((actor, idx) => makeTr(actor, idx))}
                </tbody>
            </Table>
            {confirmDelete()}
        </Container>
    )
}

export default observer(Index)

