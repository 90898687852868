import { FC } from "react"
import { Container } from "react-bootstrap"
const Test: FC = () => {
    return (
        <Container>
            <h1>Test page</h1>
        </Container>
    )
}

export default Test