import { FC, useContext, useState } from "react"
import { Container, Nav, Navbar, NavDropdown, Spinner, Offcanvas } from "react-bootstrap";
import { Context } from '../index';
import { observer } from "mobx-react-lite"
import { Link, useHistory } from "react-router-dom";

enum Roles {
    admin,
    moderator,
    unknown
}

const NavbarComponent: FC = () => {
    const { authStore } = useContext(Context)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s: boolean) => !s);
    let navbarUser
    const history = useHistory()

    function exit(event: React.SyntheticEvent) {
        authStore.logout()
        history.push("/")
    }

    if (authStore.isAuth) {
        navbarUser =
            <Navbar.Text>
                <NavDropdown title={authStore.user.email} id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.4" onClick={exit}>Выйти</NavDropdown.Item>
                </NavDropdown>
            </Navbar.Text>
    } else {
        if (authStore.isLoading) {
            navbarUser =
                <Navbar.Text>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Navbar.Text>
        } else {
            navbarUser =
                <Navbar.Text>
                    <Nav.Link as={Link} to="/login">Авторизуйтесь</Nav.Link>
                </Navbar.Text>
        }

    }
    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="light">
                <Container>
                    <Navbar.Brand href="/">Cinemacast admin</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link
                                as={Link}
                                to="/users"
                                onClick={toggleShow}
                                disabled={authStore.role !== Roles.admin}
                            >
                                Пользователи
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/actors/1/10"
                                onClick={toggleShow}
                                disabled={authStore.role !== Roles.admin && authStore.role !== Roles.moderator}
                            >
                                Актеры
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/visitors"
                                onClick={toggleShow}
                                disabled={authStore.role !== Roles.admin && authStore.role !== Roles.moderator}
                            >
                                Запросы
                            </Nav.Link>
                        </Nav>
                        <Nav>
                            {navbarUser}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default observer(NavbarComponent)