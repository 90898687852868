import React, { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap";
import { IoIosAddCircle } from "react-icons/io";
import ActorStore from '../store/ActorStore';
import YouTube from 'react-youtube'
interface addButtonProps {
    store: ActorStore
    items: string[]
}
const CustomAddVideoFrom = ({ store, items }: addButtonProps) => {

    const [show, setShow] = useState<boolean>(false)
    const [src, setSrc] = useState<string>("")

    const handleCloseConfirm = () => {
        items.unshift(src)
        setShow(false)
    }

    const extractVideoID = (url: string) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
        var match = url.match(regExp)
        if (match && match[7].length == 11) {
            return match[7]
        } else {
            return ""
        }
    }

    const getPreview = (src: string) => {
        const videoId = extractVideoID(src)
        if (videoId.length > 0) {
            return (
                <YouTube videoId={videoId} opts={{ height: "250px", width: "100%" }} />
            )
        }
        return (
            <p>Ссылка является неккоректной</p>
        )
    }

    const srcIsInvalid = () => {
        const videoId = extractVideoID(src)
        if (videoId.length > 0) {
            return false
        }
        return true
    }

    const renderModalAddForm = () => {
        return (
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Окно добавления YouTube видео</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Для добавления видео, скопируйте url из адресной строки бразура,
                        на странице видео и вставьте в поле
                    </p>
                    {getPreview(src)}
                    <Form.Control
                        type="text"
                        placeholder="Ссылка на видео из YouTube"
                        required
                        value={src}
                        onInput={(val) => setSrc(val.currentTarget.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Отмена
                    </Button>
                    <Button variant="success" id="sadsa" disabled={srcIsInvalid()} onClick={() => handleCloseConfirm()}>Добавить</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <>
            <h3>
                <label>
                    <IoIosAddCircle style={{ color: 'green', cursor: 'pointer' }} onClick={() => setShow(true)} />
                </label>
            </h3>
            {renderModalAddForm()}
        </>
    )

}

export default CustomAddVideoFrom

