import { AxiosResponse } from "axios"
import { IVisitor } from '../models/IVisitor'
import axios from 'axios'
import { env } from '../config'

const $api = axios.create({
    baseURL: env.api
})


export default class VisitorService {
    static async fetchPaginated(page: number, limit: number): Promise<AxiosResponse<{ items: IVisitor[], count: number, pages: number }>> {
        const str = [page, limit].join('/')
        return $api.get<{ items: IVisitor[], count: number, pages: number }>(`/commer/${str}`)
    }

    static async fetchVisitors(): Promise<AxiosResponse<IVisitor[]>> {
        return $api.get<IVisitor[]>('/commer')
    }

    static async createVisitor(): Promise<AxiosResponse<IVisitor>> {
        return $api.post<IVisitor>('/commer', {})
    }

    static async fetchVisitor(id: string): Promise<AxiosResponse<IVisitor>> {
        return $api.get<IVisitor>(`/commer/${id}`)
    }

    static async deleteVisitor(id: string): Promise<AxiosResponse<IVisitor>> {
        return $api.delete<IVisitor>(`/commer/${id}`)
    }

    static async updateVisitor(id: string, data: IVisitor): Promise<AxiosResponse<IVisitor>> {
        console.log(data)
        return $api.put<IVisitor>(`/commer/${id}`, data)
    }
}
