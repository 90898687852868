import $api from "../http";
import { AxiosResponse } from "axios";
import { IUser } from "../models/IUser";


export default class UserService {

    static async fetchUsers(page: string, limit: string): Promise<AxiosResponse<{ users: IUser[], count: number, pages: number }>> {
        const str = [page, limit].join('/')
        return $api.get<{ users: IUser[], count: number, pages: number }>(`/users/${str}`)
    }

    static async fetchUser(id: string): Promise<AxiosResponse<IUser>> {
        return $api.get<IUser>(`/user/${id}`)
    }

    static async upd(user: IUser): Promise<AxiosResponse<IUser>> {
        const updatePass = !!user.passwordConfirmation
        if (!updatePass) {
            delete user.password
            delete user.passwordConfirmation
        }

        if (user._id && user._id?.length > 0) {
            return $api.put<IUser>(`/user/${user._id}`, { data: user, updatePasswod: !!user.passwordConfirmation })
        }
        delete user._id
        return $api.post<IUser>(`/users`, { ...user })
    }

    static async create(user: IUser): Promise<AxiosResponse<{ user: IUser }>> {
        const updatePass = !!user.passwordConfirmation
        if (!updatePass) {
            delete user.password
            delete user.passwordConfirmation
        }
        delete user._id
        return $api.post<{ user: IUser }>(`/users`, { ...user })
    }
    static async del(id: string): Promise<AxiosResponse<IUser>> {
        return $api.delete<IUser>(`/user/${id}`)
    }
}

