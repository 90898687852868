import $api from "../http";
import { AxiosResponse } from "axios";
import { IActor } from '../models/IActor';

export default class ActorService {
    static async fetchActors(page: string, limit: string, filter: string = ''): Promise<AxiosResponse<{ actors: IActor[], count: number, pages: number }>> {
        const str = [page, limit].join('/')
        return $api.post<{ actors: IActor[], count: number, pages: number }>(`/actors/${str}`, {
            filter: {
                fio: filter,
                gender: 0,
                minAge: 0,
                minHeight: 0,
                minWeight: 0,
                maxAge: 130,
                maxHeight: 300,
                maxWeight: 300,
                race: 0,
                constitution: 0,
                eyeColor: 0,
                hairColor: 0,
            }
        })
    }

    static async fetchActor(id: string): Promise<AxiosResponse<IActor>> {
        return $api.get<IActor>(`/actor/${id}`)
    }

    static async upadateActor(actor: IActor): Promise<AxiosResponse<IActor>> {
        if (actor._id) {
            return $api.put<IActor>(`/actor/${actor._id}`, { ...actor })
        }
        return $api.post<IActor>(`/actor`, { ...actor })
    }
    static async deleteActor(id: string) {
        return $api.delete(`/actor/${id}`)
    }
}

