import { makeAutoObservable } from "mobx"

export interface IError {
    id: string
    text: string
}

export default class BaseStore {

    isLoading = false
    errorList = [] as IError[]

    constructor() {
        makeAutoObservable(this)
    }

    makeid(length = 10) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    addError(text: string) {
        this.errorList.push({ id: this.makeid(), text })
        console.log('this.errorList', this.errorList)
    }

    delError(idx: string) {
        this.errorList = this.errorList.filter(({ id, text }) => id !== idx)
    }

    clear() {
        this.errorList = []
    }

    setLoading(bool: boolean) {
        this.isLoading = bool
    }

}

const baseStore = new BaseStore()

export {
    baseStore
}