import React from 'react'
import ActorStore from '../store/ActorStore';
import CustomAddPhotoForm from './CustomAddPhotoFrom';
import CustomAddVideoFrom from './CustomAddVideoFrom';
import CustomArrowsControl from './CustomArrowsControl';


interface HeaderProps {
    label: string
    items: string[]
    store: ActorStore
    type: TypeAddForm
}

enum TypeAddForm {
    photo,
    video
}
const CustomSliderHeader = ({ label, items, store, type }: HeaderProps) => {
    const moveLeft = () => {
        items.push(items.shift() as string)
    }

    const moveRight = () => {
        items.unshift(items.pop() as string)
    }

    const getAddForm = (type: TypeAddForm) => {
        if (type === TypeAddForm.photo) {
            return <CustomAddPhotoForm store={store} items={items} />
        }
        if (type === TypeAddForm.video) {
            return <CustomAddVideoFrom store={store} items={items} />
        }
        return <></>
    }

    const isHidden = (type: TypeAddForm) => {
        if (type === TypeAddForm.photo) {
            return 1
        }
        if (type === TypeAddForm.video) {
            return 1
        }
        return 1
    }

    return (
        <div className="d-flex justify-content-between">
            <div style={{ marginLeft: 10 }} className="d-flex justify-content-start">
                <h3>
                    <span>{label}({items.length})</span>
                </h3>
                {getAddForm(type)}
            </div>
            <CustomArrowsControl leftClick={moveLeft} rightClick={moveRight} isHidden={items.length > isHidden(type) ? true : false} />
        </div>
    )
}

export default CustomSliderHeader