import React from "react"
import { observer } from "mobx-react-lite"
import { Button, Col, Form, Row } from "react-bootstrap"
import { AiFillCloseCircle } from "react-icons/ai"

interface ItemsProps {
    items: Film[]
}

interface Film {
    name: string
    date?: string
    character?: string
}
const Filmography = ({ items }: ItemsProps) => {

    const deleteByIndex = (id: number) => {
        items.splice(id, 1)
    }

    const header = () => {
        return (
            <h3>Фильмография</h3>
        )
    }


    const handlerDate = (val: string) => {
        if (val) {
            if (val.length < 5 && parseInt(val) < 2022) {
                return val
            }
            return '2022'
        }
        return '1900'
    }



    const renderFilm = (item: Film, id: number) => {
        const minDate = 1900
        const maxDate = (new Date()).getFullYear()
        return (
            <div style={{ marginTop: '10px', marginBottom: '10px' }} key={id}>
                <Row xs={12} md={12}>
                    <Col xs={3} md={2}>
                        <Form.Control
                            type="number"
                            placeholder={item.date ? item.date?.toString() : 'Год'}
                            value={item.date}
                            min={minDate}
                            max={maxDate}
                            onInput={(val) => item.date = handlerDate(val.currentTarget.value)} />
                    </Col>
                    <Col xs={6} md={7}>
                        <Form.Control
                            type="text"
                            placeholder="Название фильма"
                            value={item.name}
                            onInput={(val) => item.name = val.currentTarget.value} />
                    </Col>
                    <Col xs={3} md={3}>
                        <div style={{ position: 'relative' }} >
                            <AiFillCloseCircle
                                onClick={() => deleteByIndex(id)}
                                style={{ position: "absolute", color: "red", fontSize: "20px", top: "-12px", right: "-10px", cursor: "pointer" }}
                            />
                            <Form.Control
                                type="text"
                                placeholder="Персонаж"
                                value={item.character}
                                onInput={(val) => item.character = val.currentTarget.value} />
                        </div>
                    </Col>
                </Row >
            </div>
        )
    }

    const renderFilms = () => {
        return (
            <div style={{ padding: '5px' }}>
                {items.map((item, id) => renderFilm(item, id))}
            </div>
        )
    }

    const addButton = () => {
        return (
            <div style={{ textAlign: 'center', padding: '5px' }} >
                <Button disabled={items[items.length-1]?.name === ''} variant="success" id="skillAdd" onClick={() => items.push({ name: '', date: '', character: '' })} >
                    Добавить фильм
                </Button>
            </div>
        )
    }

    return (
        <>
            {header()}
            {renderFilms()}
            {addButton()}
        </>
    )
}

export default observer(Filmography)