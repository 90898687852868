import React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"

interface Props extends RouteProps {
    isAdmin: boolean
}

const AdminRoute = ({ isAdmin, ...routeProps }: Props) => {
    if (isAdmin) {
        return <Route {...routeProps} />
    }
    return <Redirect to="/login" />
}

export default AdminRoute