import { IUser } from '../models/IUser'
import { makeAutoObservable } from "mobx"
import UserService from '../services/UserService';
import { baseStore } from './BaseStore';

export default class UserStore {
    isLoading = false
    users = [] as IUser[]
    user = {
        _id: "",
        email: "",
        role: 1,
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        createdAt: "",
        updatedAt: "",
        iat: 0,
        exp: 0
    } as IUser
    count = 0
    pages = 0

    showError = false
    showErrorMessage = ""


    constructor() {
        makeAutoObservable(this)
    }

    setShowError(bool: boolean) {
        this.showError = bool
    }

    setShowErrorMessage(str: string) {
        this.showErrorMessage = str
    }


    setCount(count: number) {
        this.count = count
    }

    setPages(pages: number) {
        this.pages = pages
    }

    setLoading(bool: boolean) {
        this.isLoading = bool
    }

    setUsers(users: IUser[]) {
        this.users = users
    }

    setUser(user: IUser) {
        this.user = user
    }

    clearUser() {
        this.setUser({
            _id: "",
            email: "",
            role: 1,
            firstName: "",
            lastName: "",
            createdAt: "",
            updatedAt: "",
            iat: 0,
            exp: 0
        } as IUser)
    }

    clearUsers() {
        this.setUsers([])
    }

    async fetchUsers(page: string, limit: string) {
        this.setLoading(true)
        try {
            const response = await UserService.fetchUsers(page, limit)
            const { users, pages, count } = response.data
            this.clearUser()
            this.setUsers(users)
            this.setCount(count)
            this.setPages(pages)
        } catch (error) {
            this.clearUsers()
            console.log(error)
            baseStore.addError(JSON.stringify(error, undefined, 2))
        } finally {
            this.setLoading(false)
        }
    }

    async getUser(id: string) {
        this.setLoading(true)
        try {
            const response = await UserService.fetchUser(id)
            this.setUser(response.data)
        } catch (error) {
            console.log(error)
            baseStore.addError(JSON.stringify(error, undefined, 2))
        } finally {
            this.setLoading(false)
        }
    }

    async save() {
        this.setLoading(true)
        try {
            const response = await UserService.upd(this.user)
            this.setUser(response.data)
        } catch (error) {
            console.log(error)
            baseStore.addError(JSON.stringify(error, undefined, 2))
        } finally {
            this.setLoading(false)
            return this.user._id ?? ''
        }
    }

    async create() {
        this.setLoading(true)
        try {
            delete this.user._id;
            const response = await UserService.create(this.user)
            const { user } = response.data
            this.setUser(user)
        } catch (error) {
            console.log(error)
            baseStore.addError(JSON.stringify(error, undefined, 2))
        } finally {
            this.setLoading(false)
            return this.user._id ?? ''
        }
    }

    async delete(id: string) {
        this.setLoading(true)
        try {
            const response = await UserService.del(id)
            if (response!.status === 403) {
                console.log('delete', response)
            }

            this.clearUser()
        } catch (error) {
            console.log(error)
            baseStore.addError(JSON.stringify(error, undefined, 2))

        } finally {
            this.setLoading(false)
        }
    }


}