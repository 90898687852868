import AuthStore from "./AuthStore"
import ActorStore from "./ActorStore"
import UserStore from "./UserStore"
import VisitorStore from "./VisitorStore"

const actorStore = new ActorStore()
const authStore = new AuthStore()
const userStore = new UserStore()
const visitorStore = new VisitorStore()

export interface State {
    actorStore: ActorStore,
    authStore: AuthStore,
    userStore: UserStore,
    visitorStore: VisitorStore,
}


export {
    authStore,
    actorStore,
    userStore,
    visitorStore
}