import { observer } from "mobx-react-lite"
import { Container, Table, Modal, Button, Stack, Pagination } from "react-bootstrap"
import { useContext, useState, useEffect } from "react"
import { Context } from "../../index"
import { useHistory, useParams } from "react-router-dom"
enum Roles {
    admin,
    moderator,
    unknown
}

const Visistors = () => {
    const history = useHistory()

    const { visitorStore, userStore, authStore } = useContext(Context)

    const { page = '1' } = useParams<{ page: string }>()
    const { limit = '10' } = useParams<{ limit: string }>()

    const [show, setShow] = useState<boolean>(false)
    const [toDelete, setToDelete] = useState<string>('')


    const getVisitors = () => {
        document.title = "Данные запросов"

        visitorStore.getVisitorsPaginated(parseInt(page), parseInt(limit)).then(() => console.log(visitorStore.visitors))
    }
    useEffect(() => {
        getVisitors()
    }, [page])

    const handleCloseReset = () => {
        setShow(false)
    }

    const handleShow = (id: string | undefined) => {
        if (id) {
            setShow(true)
            setToDelete(id)
        }
    }

    const deleteItem = async (id: string | undefined) => {
        if (id) {
            await visitorStore.deleteVisitById(id)
            getVisitors()
        }
    }

    const handleCloseConfirm = async () => {
        await deleteItem(toDelete)
        setShow(false)

    }


    const toPageHandler = (number: number) => {
        history.push(`/visitors/${number}/${limit}`)
    }

    const paginator = () => {
        let items = []
        let end = visitorStore.pages

        for (let number = 1; number <= end; number++) {
            items.push(
                <Pagination.Item disabled={visitorStore.count > ((number - 1) * parseInt(limit)) ? false : true} key={number} active={number === parseInt(page)} onClick={() => toPageHandler(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        return (
            <Pagination style={{ marginBottom: 0 }}>
                {items}
            </Pagination>
        )
    }


    return (
        <Container>
            <Stack direction="horizontal" gap={3} style={{ marginBottom: 10 }}>
                {paginator()}
            </Stack>
            <Table striped bordered hover style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th>Почта</th>
                        <th>Удалить</th>
                    </tr>
                </thead>
                <tbody>
                    {visitorStore.visitors.map((visitor, idx) => {
                        return (
                            <tr key={visitor._id}  >
                                <td style={{ maxWidth: '100%' }} onClick={() => history.push(`/visitor/${visitor._id}`)} >{visitor?.email}</td>
                                <td style={{ textAlign: 'center' }} >
                                    <Button disabled={authStore.role !== Roles.admin} variant="danger" onClick={() => { handleShow(visitor._id) }}>Удалить</Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <Modal show={show} onHide={handleCloseReset}>
                <Modal.Header closeButton>
                    <Modal.Title>Подтвердите удаление</Modal.Title>
                </Modal.Header>
                <Modal.Body>Вы хотите удалить данные?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReset}>
                        Отмена
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirm}>Удалить</Button>

                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default observer(Visistors)

function paginator(): import("react").ReactNode {
    throw new Error("Function not implemented.")
}
