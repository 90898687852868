import "react-datepicker/dist/react-datepicker.css";
import { FC } from "react"
import { Container, Image, Spinner, Stack, Row, Col, Carousel, Form, Button } from "react-bootstrap"
import { useContext, useState, useEffect } from "react"
import { Context } from "../index"
import { observer } from "mobx-react-lite"
import { useHistory, useParams } from "react-router-dom";
import { citizenship, gender, constitution, race, eyeColor, hairColor, education, media } from '../helpers/types'

import CustomPhotoSlider from "../components/CustomPhotoSlider"
import CustomVideoSlider from "../components/CustomVideoSlider"
import Skills from "../components/Skills"
import Filmography from "../components/Filmography"
import Media from "../components/Media"

const ActorPage: FC = () => {

    const { id } = useParams<{ id: string }>()
    const { actorStore } = useContext(Context)
    const history = useHistory()


    const getFullName = (firstname?: string, lastname?: string, patronymic?: string) => {
        return [firstname, lastname, patronymic].join(' ')
    }

    useEffect(() => {
        if (id) actorStore.getActor(id).then(() => document.title = getFullName(actorStore.actor.firstName, actorStore.actor.lastName, actorStore.actor.patronymic))

    }, [])

    if (actorStore.isLoading) {
        return (
            <Container>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        )
    }

    const getCharacteristicActor = (lable?: string, data?: JSX.Element) => {
        return (
            <>
                <Row style={{ marginBottom: '5px' }}>
                    <Col lg={3} md={4} xs={4}>
                        <p>{lable}</p>
                    </Col>
                    <Col lg={9} md={8} xs={8}>
                        {data ? data : '⸻'}
                    </Col>
                </Row>
            </>
        )
    }


    const getFields = () => {
        return <>
            {getCharacteristicActor("Имя",
                <Form.Control
                    type="text"
                    placeholder="Имя"
                    required
                    value={actorStore.actor.firstName}
                    onInput={(val) => actorStore.actor.firstName = val.currentTarget.value} />)}
            {getCharacteristicActor("Фамилия",
                <Form.Control
                    type="text"
                    placeholder="Фамилия"
                    required
                    value={actorStore.actor.lastName}
                    onInput={(val) => actorStore.actor.lastName = val.currentTarget.value} />)}
            {getCharacteristicActor("Отчество",
                <Form.Control
                    type="text"
                    placeholder="Отчество"
                    value={actorStore.actor.patronymic}
                    onInput={(val) => actorStore.actor.patronymic = val.currentTarget.value} />)}
            {getCharacteristicActor("Пол",
                <Form.Select
                    value={actorStore.actor.gender}
                    onChange={(val) => actorStore.actor.gender = Number(val.target.value)}
                >
                    <option value="DEFAULT" disabled>Выберете пол</option>
                    {gender.map((item, idx) => {
                        return (
                            <option
                                key={idx}
                                value={item.id}>{item.text}
                            </option>
                        );
                    })}
                </Form.Select>)}
            {getCharacteristicActor("Дата рождения",
                <Form.Control
                    type="date"
                    placeholder="Дата рождения"
                    required
                    value={actorStore.actor.birthDay}
                    onInput={(val) => actorStore.actor.birthDay = val.currentTarget.value} />)}

            {getCharacteristicActor("Гражданство",
                <Form.Select
                    value={actorStore.actor.citizenship}
                    onChange={(val) => actorStore.actor.citizenship = Number(val.target.value)}
                >
                    <option value="DEFAULT" disabled>Гражданство</option>
                    {citizenship.map((item, idx) => {
                        return (
                            <option
                                key={idx}
                                value={item.id}>{item.text}
                            </option>
                        );
                    })}
                </Form.Select>)}
            {getCharacteristicActor("Театр",
                <Form.Control
                    type="text"
                    placeholder="Театр"
                    value={actorStore.actor.theater}
                    onInput={(val) => actorStore.actor.theater = val.currentTarget.value} />)}

            {getCharacteristicActor("Город",
                <Form.Control
                    type="text"
                    placeholder="Город"
                    value={actorStore.actor.city}
                    onInput={(val) => actorStore.actor.city = val.currentTarget.value} />)}
            {getCharacteristicActor("Образование",
                <Form.Select
                    value={actorStore.actor.education}
                    onChange={(val) => actorStore.actor.education = Number(val.target.value)}
                >
                    <option value="DEFAULT" disabled>Выберете место образования</option>
                    {education.map((item, idx) => {
                        return (
                            <option
                                key={idx}
                                value={item.id}>{item.text}
                            </option>
                        );
                    })}
                </Form.Select>)}

            {getCharacteristicActor("Телосложение",
                <Form.Select
                    value={actorStore.actor.constitution}
                    onChange={(val) => actorStore.actor.constitution = Number(val.target.value)}
                >
                    <option value="DEFAULT" disabled>Телосложение</option>
                    {constitution.map((item, idx) => {
                        return (
                            <option
                                key={idx}
                                value={item.id}>{item.text}
                            </option>
                        );
                    })}
                </Form.Select>)}

            {getCharacteristicActor("Тип внешности",
                <Form.Select
                    value={actorStore.actor.race}
                    onChange={(val) => actorStore.actor.race = Number(val.target.value)}
                >
                    <option value="DEFAULT" disabled>Выберете тип внешности</option>
                    {race.map((item, idx) => {
                        return (
                            <option
                                key={idx}
                                value={item.id}>{item.text}
                            </option>
                        );
                    })}
                </Form.Select>)}

            {getCharacteristicActor("Вес",
                <Form.Control
                    type="number"
                    placeholder="Вес"
                    value={actorStore.actor.weight}
                    onChange={(val) => actorStore.actor.weight = (Number(val.target.value) <= 300 ? val.target.value ? parseInt(val.target.value) : 0 : actorStore.actor.weight)}
                    min="0"
                    max="300" />)}
            {getCharacteristicActor("Рост",
                <Form.Control
                    type="number"
                    placeholder="Рост"
                    value={actorStore.actor.height}
                    onChange={(val) => actorStore.actor.height = (Number(val.target.value) <= 300 ? val.target.value ? parseInt(val.target.value) : 0 : actorStore.actor.height)}
                    min="0"
                    max="300" />)}
            {getCharacteristicActor("Цвет волос",
                <Form.Select
                    value={actorStore.actor.hairColor}
                    onChange={(val) => actorStore.actor.hairColor = Number(val.target.value)}
                >
                    <option value="DEFAULT" disabled>Цвет волос</option>
                    {hairColor.map((item, idx) => {
                        return (
                            <option
                                key={idx}
                                value={item.id}>{item.text}
                            </option>
                        );
                    })}
                </Form.Select>)}

            {getCharacteristicActor("Цвет глаз",
                <Form.Select
                    value={actorStore.actor.eyeColor}
                    onChange={(val) => actorStore.actor.eyeColor = Number(val.target.value)}
                >
                    <option value="DEFAULT" disabled>Цвет глаз</option>
                    {eyeColor.map((item, idx) => {
                        return (
                            <option
                                key={idx}
                                value={item.id}>{item.text}
                            </option>
                        );
                    })}
                </Form.Select>)}

        </>
    }

    const getForm = () => {
        return (
            <Form id="formFields" onSubmit={(e) => saveActor()}>
                {getFields()}
            </Form>
        )
    }

    const saveActor = async () => {
        const newId = await actorStore.save()
        if (!id) {
            history.push(`/actor/${newId}`)
        }
    }

    const avatar = () => {
        return (
            <div style={{ margin: 'auto', backgroundColor: "lightgray", textAlign: 'center', height: "100%", maxHeight: "555px" }}>
                {
                    actorStore.actor.avatar ?
                        <img
                            style={{ width: 'auto', maxWidth: '100%', height: "100%", maxHeight: "700px", objectFit: "cover" }}
                            alt="avatar"
                            src={actorStore.actor.avatar} />
                        :
                        <></>
                }
            </div>

        )
    }

    return (
        <Container>
            <Stack direction="horizontal" >
                <h1>{getFullName(actorStore.actor.lastName, actorStore.actor.firstName, actorStore.actor.patronymic)}</h1>
                <Button form="formFields" type="submit" variant="success" className="ms-auto" > Сохранить </Button>
            </Stack>

            <Row>
                <Col lg={6} md={12} xs={12}>
                    {avatar()}
                </Col>
                <Col lg={6} md={12} xs={12}>
                    <CustomPhotoSlider items={actorStore.actor.photos ?? []} store={actorStore} />
                    <CustomVideoSlider items={actorStore.actor.videos ?? []} store={actorStore} />
                </Col>

            </Row><br /><Row>
                <Col lg={6} md={12} xs={12}>
                    {getForm()}
                </Col>
                <Col lg={6} md={12} xs={12}>
                    <Row>
                        <Col md={12}>
                            <Skills items={actorStore.actor.skills ?? []} />
                        </Col>
                        <Col md={12}>
                            <Media items={actorStore.actor.media ?? []} />
                        </Col>
                        <Col md={12}>
                            <Filmography items={actorStore.actor.filmography ?? []} />
                        </Col>

                    </Row>
                </Col>
            </Row>

        </Container >
    )
}

export default observer(ActorPage)

