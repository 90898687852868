import $api from "../http";
import { AxiosResponse } from "axios";
import IUploaded from "../models/IUploaded";

export default class ImageService {
    static async upload(list: File[]): Promise<AxiosResponse<IUploaded[]>> {
        let formData = new FormData();
        list.map(file => {
            formData.append('photos', file)
        })
        return $api.post<IUploaded[]>('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}

