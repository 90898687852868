import $api from "../http";
import { AxiosResponse } from "axios";
import { AuthResponse } from "../models/response/AuthResponse";


export default class AuthService {
    static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/sessions', { email, password })
    }


    static async registration(firstName: string, lastName: string, email: string, password: string, passwordConfirmation: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/sessions', { firstName, lastName, email, password, passwordConfirmation })
    }

    static async logout(): Promise<void> {
        return $api.post('/logout')
    }

    static refreshToken(): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/sessions/refresh')
    }
}

