import { FC } from "react"
import { Button, Container } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
const Page404: FC = () => {
    const history = useHistory()
    async function home(event: React.SyntheticEvent) {
        event.preventDefault()
        history.push("/")
    }
    return (
        <Container>
            <h1>404 - Not Found!</h1>
            <Button variant="primary" type="submit" onClick={home}>
                Go Home
            </Button>
        </Container>
    )
}

export default Page404