

import React from 'react'
import { Col } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai'
import ReactPlayer from 'react-player'

import YouTube from 'react-youtube'

interface IItem {
    id: number
    src: string
}

interface ItemProps {
    video: string
    id: number
    setShowConfirmModal: (value: React.SetStateAction<boolean>) => void
    setToDelete: (value: React.SetStateAction<IItem>) => void
}


const CustomSliderVideoItem = ({ id, video, setToDelete, setShowConfirmModal }: ItemProps) => {

    let isHidden = false
    if (id >= 1) {
        isHidden = true
    }

    const handleShow = (event: React.MouseEvent<SVGElement>) => {
        event.preventDefault()
        const [src, id] = event.currentTarget.id.split('-')
        setToDelete({ id: parseInt(id), src })
        setShowConfirmModal(true)
    }

    return (
        <Col md={12} xs={12} key={id} hidden={isHidden}>
            <div className="gallery" style={{ position: 'relative' }}>
                <AiFillCloseCircle
                    id={`${video}-${id}`}
                    onClick={handleShow}
                    style={{ position: "absolute", color: "red", fontSize: "25px", top: "-15px", right: "-26px", cursor: "pointer" }}
                />
                <div style={{ cursor: "pointer", marginLeft: "10px", marginRight: "-10px" }}>
                    <ReactPlayer url={video} height={'250px'} width={'100%'} />
                </div>
            </div>
        </Col>
    )
}

export default CustomSliderVideoItem