import { FC } from "react"
import { useContext, useState } from "react"
import { Button, Form, Container, Alert } from "react-bootstrap"
import { Redirect, useHistory } from "react-router-dom"
import { Context } from "../index"
const Login: FC = () => {
    document.title = "Авторизация"
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const { authStore } = useContext(Context)
    const history = useHistory()

    const [showAlert, setShowAlert] = useState<boolean>(false)

    async function login(event: React.SyntheticEvent) {
        event.preventDefault()
        await authStore.login(email, password)
        setShowAlert(authStore.showError)
        if (authStore.isAuth) history.push("/")
    }

    if (authStore.isAuth) {
        return <Redirect to="/" />
    }

    const AlertDismissibleExample = () => {
        if (showAlert) {
            return (
                <Alert transition={true} variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    <Alert.Heading>Ой! Что-то пошло не так</Alert.Heading>
                    <p>
                        {authStore.showErrorMessage}
                    </p>
                </Alert>
            );
        }
        return <></>
    }

    return (
        <>
            {AlertDismissibleExample()}

            <Container>
                <Form onSubmit={login} style={{ width: '50%', margin: 'auto', marginTop: '5%', }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email"
                            placeholder="Email"
                            onChange={e => setEmail(e.target.value)}
                            value={email} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control type="password"
                            placeholder="Пароль"
                            onChange={e => setPassword(e.target.value)}
                            value={password} />
                    </Form.Group>
                    <div style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
                        <Button variant="primary" type="submit" style={{ width: '50%', margin: 'auto' }}>
                            Вход
                        </Button>
                    </div>
                </Form>
            </Container>
        </>
    )
}

export default Login