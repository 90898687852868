import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { baseStore, IError } from '../store/BaseStore';
interface Props {
    idx: number
    error: IError
}

const CustomAlert = ({ error, idx }: Props) => {
    const [show, setShow] = useState(true);

    const hadleDel = () => {
        baseStore.delError(error.id)
        setShow(false)
    }
    return (
        <Alert variant="danger" key={idx} style={{ maxWidth: 550, width: '100%', padding: 5 }}>
            <Alert.Heading>Ooops?!</Alert.Heading>
            <div style={{ lineBreak: 'auto' }}>
                <pre>
                    {error.text}
                </pre>
            </div>
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={hadleDel} variant="outline-success">
                    Закрыть!
                </Button>
            </div>
        </Alert>
    )
}

export default CustomAlert