import React from "react"
import { observer } from "mobx-react-lite"
import { Col, Row, Form, FormControl, Button } from "react-bootstrap"
import { AiFillCloseCircle } from "react-icons/ai"
import { MediaType, media } from '../helpers/types'

interface Media {
    type: MediaType,
    src: string
}

interface ItemsProps {
    items: Media[]
}

const Media = ({ items }: ItemsProps) => {


    const deleteByIndex = (id: number) => {
        items.splice(id, 1)
    }


    const getPatternInput = (type: MediaType) => {
        switch (type) {
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return /\D/
                break;
            case 1:
                return /^\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$/
                break;
            case 2:
                return /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
                break;
            default:
                return /\D/
                break;
        }
    }

    const getPlaceHolderInput = (type: MediaType) => {
        switch (type) {
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return 'Вставьте ссылку на ресурс'
                break;
            case 1:
                return "Введите номер телефона"
                break;
            case 2:
                return 'Введите email'
                break;
            default:
                return 'Введите данные'
                break;
        }
    }


    const inputHadlerVlidation = (type: MediaType, val: string) => {
        const regExp = new RegExp(getPatternInput(type))
        return regExp.test(val)
    }

    const itemRender = (item: Media, idx: number) => {
        return (
            <div style={{ marginTop: '10px', marginBottom: '10px' }} key={idx}>
                <Row>
                    <Col xs={3} md={3}>
                        <Form.Select aria-label="Default select example" value={item.type} onChange={(e) => item.type = Number(e.currentTarget.value)}>
                            <option value="DEFAULT" disabled>Тип контакта</option>
                            {media.map(({ id, text }, idx) => <option key={id} value={id}>{text}</option>)}
                        </Form.Select>
                    </Col>

                    <Col xs={9} md={9}>
                        <div style={{ position: 'relative' }} >
                            <AiFillCloseCircle
                                onClick={() => deleteByIndex(idx)}
                                style={{ position: "absolute", color: "red", fontSize: "20px", top: "-12px", right: "-10px", cursor: "pointer" }}
                            />
                            <FormControl
                                isValid={inputHadlerVlidation(item.type, item.src)}
                                isInvalid={!inputHadlerVlidation(item.type, item.src)}
                                placeholder={getPlaceHolderInput(item.type)}
                                value={item.src}
                                onInput={(e) => item.src = e.currentTarget.value}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const addButton = () => {
        return (
            <div style={{ textAlign: 'center', padding: '5px' }} >
                <Button disabled={items[items.length - 1]?.src === ''} variant="success" id="skillAdd" onClick={() => items.push({ src: '', type: 6 })} >
                    Добавить контакт
                </Button>
            </div>
        )
    }


    const header = () => {
        return (
            <h3>Контактные данные</h3>
        )
    }


    return (
        <>
            {header()}
            {items.map((item, id) => itemRender(item, id))}
            {addButton()}
        </>
    )
}

export default observer(Media)