import React from "react"
import { Button, Modal } from "react-bootstrap"
import ActorStore from "../store/ActorStore"
interface ModalFullSizePhotoProps {
    id: number
    src: string
    store: ActorStore
    showFullSize: boolean
    setShowFullSize: (value: React.SetStateAction<boolean>) => void
}
const ModalFullSizePhoto = ({ id, src, showFullSize, setShowFullSize, store }: ModalFullSizePhotoProps) => {

    const getImageFull = (id: number, photo: string) => {
        return <img key={id} src={photo} style={{ width: '100%', height: '100%' }} onClick={() => { setShowFullSize(true) }} />
    }

    const handleCloseReset = () => {
        setShowFullSize(false)
    }

    return (
        <Modal show={showFullSize} onHide={() => setShowFullSize(false)} >
            <Modal.Header closeButton hidden={true}>
            </Modal.Header>
            <Modal.Body>
                {getImageFull(id, src)}
            </Modal.Body>
            <Modal.Footer style={{ margin: "auto" }}>
                <Button variant="primary" onClick={() => { store.changeAvatar(src); handleCloseReset() }}>
                    Сделать главной фотографией
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalFullSizePhoto