import React from "react"
import { Container, Image, Spinner, Stack, Row, Col, Carousel, Form, Button, InputGroup } from "react-bootstrap"
import { useContext, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useHistory, useParams } from "react-router-dom";
import { Context } from "../index"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { roles } from "../helpers/types";

enum Roles {
    admin,
    moderator,
    unknown
}

const UserPage = () => {
    const { id } = useParams<{ id: string }>()
    const { userStore, authStore } = useContext(Context)
    const history = useHistory()

    const [changePassword, setChangePassword] = useState(false)
    const [showPassword, setShowPassrowd] = useState(false)
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [maySave, setMaySave] = useState(true)
    const getFullName = (firstname?: string, lastname?: string, patronymic?: string) => {
        return [firstname, lastname, patronymic].join(' ')
    }


    useEffect(() => {
        async function getUser() {
            if (id) {
                await userStore.getUser(id)
                document.title = getFullName(userStore.user.firstName, userStore.user.lastName)
                setPassword(userStore.user.password ?? "")
            } else {
                document.title = 'Новый пользователь'
                setChangePassword(true)
            }

        }
        getUser()
    }, [id])

    useEffect(() => {
        if (changePassword) {
            setMaySave(false)
            userStore.user.password = ""
        } else {
            userStore.user.password = userStore.user.password ? userStore.user.password : password
        }
    }, [changePassword, password])

    const checkNameAndFamily = () => {
        if (userStore.user.firstName && userStore.user.lastName && userStore.user.email) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (checkNameAndFamily() && ((userStore.user.password && !changePassword) || (newPassword.length >= 6) && (newPassword === confirmPassword))) {
            setMaySave(true)
            userStore.user.password = newPassword
            userStore.user.passwordConfirmation = confirmPassword
        } else {
            setMaySave(false)
            userStore.user.password = password
            userStore.user.passwordConfirmation = password
        }
    }, [newPassword, confirmPassword, changePassword, password])


    if (userStore.isLoading) {
        return (
            <Container>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        )
    }

    const fieldHelper = (lable?: string, data?: JSX.Element) => {
        return (
            <>
                <Row style={{ marginBottom: '5px' }}>
                    <Col lg={3} md={4} xs={4}>
                        <p>{lable}</p>
                    </Col>
                    <Col lg={9} md={8} xs={8}>
                        {data ? data : '⸻'}
                    </Col>
                </Row>
            </>
        )
    }

    const validateMail = (email: string) => {
        const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
        return pattern.test(email)
    }

    const getFields = () => {
        return <>
            {
                fieldHelper("Имя",
                    <Form.Control
                        type="text"
                        placeholder="Имя"
                        required
                        value={userStore.user.firstName}
                        onInput={(val) => userStore.user.firstName = val.currentTarget.value}
                    />
                )
            }

            {
                fieldHelper("Фамилия",
                    <Form.Control
                        type="text"
                        placeholder="Фамилия"
                        required
                        value={userStore.user.lastName}
                        onInput={(val) => userStore.user.lastName = val.currentTarget.value}
                    />
                )
            }

            {
                fieldHelper("Почта",
                    <Form.Control
                        type="email"
                        placeholder="Почта"
                        required
                        isValid={validateMail(userStore.user.email)}
                        isInvalid={!validateMail(userStore.user.email)}
                        value={userStore.user.email}
                        onInput={(val) => userStore.user.email = val.currentTarget.value}
                    />
                )
            }

            {authStore.role === Roles.admin ?
                fieldHelper("Роль",
                    <Form.Select
                        value={userStore.user.role}
                        onChange={(val) => userStore.user.role = Number(val.target.value)}
                    >
                        <option value="DEFAULT" disabled>Выберете роль</option>
                        {roles.map((item, idx) => {
                            return (
                                <option
                                    key={idx}
                                    value={item.id}>{item.text}
                                </option>
                            );
                        })}
                    </Form.Select>) : <></>
            }

            {changePasswordFields()}



        </>
    }


    const checkPasswords = (password: string) => {
        return ((password.length >= 6) && (password === confirmPassword))
    }

    const getMinCountChars = (val: string, min: number) => {
        if (val.length > min) {
            return 0
        }
        if (val.length <= min) {
            return min - val.length
        }
    }


    const changePasswordFields = () => {
        if (!changePassword) return (
            <div style={{ textAlign: 'center' }}>
                <Button variant="outline-secondary" id="button-addon2" onClick={() => setChangePassword(!changePassword)}>
                    Изменить пароль
                </Button>
            </div>)
        return (
            <>
                {
                    fieldHelper("Новый пароль",
                        <>
                            <InputGroup >

                                <Form.Control
                                    type={showPassword ? 'password' : 'text'}
                                    placeholder="Пароль"
                                    required
                                    min={6}
                                    value={newPassword}
                                    onInput={(val) => setNewPassword(val.currentTarget.value)}
                                    aria-describedby="basic-addon2"
                                    isValid={checkPasswords(newPassword)}
                                    isInvalid={!checkPasswords(newPassword)}
                                />
                                <Button variant="outline-secondary" id="button-addon2" onClick={() => setShowPassrowd(!showPassword)}>
                                    {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                </Button>
                            </InputGroup>
                            <Form.Text className="text-muted" hidden={getMinCountChars(newPassword, 6) === 0}>
                                Введите еще {getMinCountChars(newPassword, 6)} символов
                            </Form.Text>

                        </>
                    )
                }
                {
                    fieldHelper("Подтвердите пароль",
                        <>
                            <InputGroup className="mb-3">

                                <Form.Control
                                    type={showPassword ? 'password' : 'text'}
                                    placeholder="Пароль"
                                    required
                                    min={6}
                                    value={confirmPassword}
                                    onInput={(val) => setConfirmPassword(val.currentTarget.value)}
                                    isValid={checkPasswords(newPassword)}
                                    isInvalid={!checkPasswords(newPassword)}
                                    aria-describedby="basic-addon2"
                                />
                                <Button variant="outline-secondary" id="button-addon2" onClick={() => setShowPassrowd(!showPassword)}>
                                    {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                </Button>
                            </InputGroup>
                            <Form.Text className="text-muted" hidden={getMinCountChars(confirmPassword, 6) === 0}>
                                Введите еще {getMinCountChars(confirmPassword, 6)} символов
                            </Form.Text>

                        </>
                    )
                }
            </>
        )
    }

    const getForm = () => {
        return (
            <Form id="formFields" onSubmit={(e) => saveUser()}>
                {getFields()}
            </Form>
        )
    }

    const saveUser = async () => {
        let newId
        if (!id) {
            newId = await userStore.create()
            history.push(`/user/${newId}`)
        } else {
            newId = await userStore.save()
        }
    }

    return (
        <>
            <Container>
                <Stack direction="horizontal" >
                    <h1>{getFullName(userStore.user.firstName, userStore.user.lastName)}</h1>
                    <Button form="formFields" type="submit" variant="success" className="ms-auto" disabled={!maySave} > Сохранить </Button>
                </Stack>

                <Row>
                    <Col lg={6} md={12} xs={12}>
                    </Col>
                    <Col lg={6} md={12} xs={12}>
                    </Col>

                </Row><br /><Row>
                    <Col lg={6} md={12} xs={12}>
                        {getForm()}
                    </Col>
                    <Col lg={6} md={12} xs={12}>
                        <Row>

                        </Row>
                    </Col>
                </Row>

            </Container >
        </>
    )
}

export default observer(UserPage)