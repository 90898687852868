import axios, { AxiosRequestConfig } from 'axios'
import { AxiosResponse } from 'axios';
import { AuthResponse } from '../models/response/AuthResponse';
import { env } from '../config';
export const API_URL = env.api

const $api = axios.create({
    baseURL: API_URL
})

$api.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config && config.headers) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    return config
})

$api.interceptors.response.use(
    (config: AxiosResponse) => { return config },
    async (error) => {
        const originalRequest = error.config
        if (error.response.status === 403 && error.config && !error.config._isRetry) {
            try {
                originalRequest._isRetry = true
                const response = await axios.post<AuthResponse>(
                    `${API_URL}/sessions/refresh`,
                    { refreshToken: localStorage.getItem('refreshToken') },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
                        }
                    })
                localStorage.setItem('token', response.data.accessToken)
                return $api.request(originalRequest)
            } catch (e) {
                console.log('not working! ')
            }
        }
        return error
    }
)

export default $api