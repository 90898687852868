import { FC } from "react"

import { Container} from "react-bootstrap"

const IndexPage: FC = () => {
    document.title = "Главная"
    return (
        <Container>
            <h1>Панель управления</h1>
            <p>ТУт можно добавлять/изменять/удалять актеров <br/> управлять другими пользователями Панели управления</p>
        </Container>
    )
}

export default IndexPage

