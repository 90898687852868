import React from "react"
import { IoIosAddCircle } from "react-icons/io";
import IUploaded from "../models/IUploaded";
import ActorStore from '../store/ActorStore';

interface addButtonProps {
    store: ActorStore
    items: string[]
}
const CustomAddPhotoForm = ({ store, items }: addButtonProps) => {
    const addUploadedPhotosToCarusel = (uploaded: IUploaded[]) => {
        uploaded.map((file: IUploaded) => {
            items.unshift(file.path)
        })
    }

    const load = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const files = event.target.files
        console.log('files', files)

        if (files && store) {
            const toSend = Array.from(files).filter(file => {
                if(file.size <= 7340032){
                    return file
                }else{
                    alert(`Файл с именем ${file.name} слишком велик, размер загружаемого файла должен быть не более 7 Мегабайт`)
                }
            })
            const uploaded = await store.upload(toSend)
            addUploadedPhotosToCarusel(uploaded)
        }
    }


    return (
        <>
            <h3>
                <label htmlFor="files">
                    <IoIosAddCircle style={{ color: 'green', cursor: 'pointer' }} />
                </label>
            </h3>
            <form id="form-id" method="post" encType="multipart/form-data">
                <input type="hidden" name="headers[authorization]" value="" />
                <input id="files" hidden={true} accept="image/*" type="file" name="items[]" multiple onChange={load} />
            </form>
        </>
    )

}

export default CustomAddPhotoForm

