import './custom.css'
import React from "react"
import { useState } from 'react';
import { Row } from 'react-bootstrap'
import ActorStore from "../store/ActorStore"


import ModalConfirmDeletePhoto from './ModalConfirmDeletePhoto'
import ModalFullSizePhoto from './ModalFullSizePhoto'
import CustomSliderHeader from './CustomSliderHeader'
import CustomSliderPhotoItem from './CustomSliderPhotoItem'

import { observer } from "mobx-react-lite"

interface Props {
    items: string[]
    editMode?: boolean
    store: ActorStore
}
interface IPhoto {
    id: number
    photo: string
}
enum TypeAddForm{
    photo,
    video
}

const CustomPhotoSlider = ({ items = [], store, editMode = false }: Props) => {

    const [toDelete, setToDelete] = useState<IPhoto>({ id: 0, photo: '' })
    const [showFullSize, setShowFullSize] = useState<boolean>(false)
    const [fullSizePhoto, setFullSizePhoto] = useState<IPhoto>({ photo: '', id: 0 })
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

    return (
        <div>
            <ModalFullSizePhoto
                id={fullSizePhoto.id}
                src={fullSizePhoto.photo}
                store={store}
                showFullSize={showFullSize}
                setShowFullSize={setShowFullSize}
            />
            <ModalConfirmDeletePhoto
                id={toDelete.id}
                src={toDelete.photo}
                store={store}
                showConfirmModal={showConfirmModal}
                setShowConfirmModal={setShowConfirmModal}
                items={items}
            />
            <div style={{ marginLeft: -10, marginRight: 10, userSelect: "none" }} >
                <CustomSliderHeader label="Фото" items={items} store={store} type={TypeAddForm.photo} />
                <Row>
                    {items.map((photo, id) => <CustomSliderPhotoItem key={id} id={id} photo={photo} setShowFullSize={setShowFullSize} setFullSizePhoto={setFullSizePhoto} setToDelete={setToDelete} setShowConfirmModal={setShowConfirmModal} />)}
                </Row>
            </div>
        </div>
    )
}

export default observer(CustomPhotoSlider)