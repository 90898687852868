import { Container, Spinner, Row, Col, Table } from "react-bootstrap"
import { useContext, useState, useEffect } from "react"
import { Context } from "../../index"
import { useHistory, useParams } from "react-router-dom";
import { IActor } from '../../models/IActor';
import { IVisitor } from '../../models/IVisitor';


const Visistor = () => {

    const { id } = useParams<{ id: string }>()
    const { actorStore, visitorStore } = useContext(Context)
    const [visitor, setVisitor] = useState<IVisitor>({})
    const [actors, setActors] = useState<Array<IActor>>([] as Array<IActor>)
    const history = useHistory()

    useEffect(() => {
        loadVisitorData().then((visitorData) => {
            setVisitor(visitorData)
            if (visitorData?.visited?.length) {
                return loadVisitor(visitorData.visited).then(res => setActors(res))
            }
        })
    }, [id])

    const loadVisitorData = (): Promise<IVisitor> => {
        return visitorStore.getVisitor(id)
    }

    const loadVisitor = (ids: string[]) => {
        const result = ids.map((actorId) => {
            return actorStore.getActor(actorId)
        })
        return Promise.all(result)
    }

    if (visitorStore.isLoading && actorStore.isLoading) {
        return (
            <Container>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        )
    }

    const makeTr = (actor: IActor, idx: number) => {
        return (
            <tr key={actor._id} style={{ cursor: 'pointer' }} >
                <td >{idx + 1}</td>
                <td onClick={() => history.push(`/actor/${actor._id}`)} >
                    {[actor.lastName, actor.firstName].join(' ')}
                </td>
            </tr >
        )
    }

    return (
        <Container>
            <Row>
                <Col md={6} xs={12}>
                    <Row>
                        <Col md={6} xs={12}>Фамилия:</Col>
                        <Col md={6} xs={12}>{visitorStore.visitor.lastName}</Col>

                        <Col md={6} xs={12}>Имя:</Col>
                        <Col md={6} xs={12}>{visitorStore.visitor.firstName}</Col>

                        <Col md={6} xs={12}>Номер телефона:</Col>
                        <Col md={6} xs={12}><a href={`tel:${visitorStore.visitor.phone}`}>{visitorStore.visitor.phone}</a></Col>

                        <Col md={6} xs={12}>Почта:</Col>
                        <Col md={6} xs={12}><a href={`mailto:${visitorStore.visitor.email}`}>{visitorStore.visitor.email}</a></Col>
                    </Row>
                </Col>
                <Col md={6} xs={12}>
                    <Table striped bordered hover style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th >Запросил контакты у данных актеров</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                actors
                                    .filter((actor) => !!actor)
                                    .map((actor, idx) => makeTr(actor, idx))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

export default Visistor