import React from 'react';
import ReactDOM from 'react-dom';
import { createContext } from 'react';
import App from './App';

import { authStore, actorStore, userStore, visitorStore, State } from './store/GlobalStore'

export const Context = createContext<State>({ authStore, actorStore, userStore, visitorStore })

ReactDOM.render(
  <Context.Provider value={{ authStore, actorStore, userStore, visitorStore }}>
    <App />
  </Context.Provider>, document.getElementById('root')
);

