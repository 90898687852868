import './custom.css'
import React from "react"
import { useState } from 'react';
import { Row } from 'react-bootstrap'
import ActorStore from "../store/ActorStore"


import ModalConfirmDeleteVideo from './ModalConfirmDeleteVideo'
import CustomSliderHeader from './CustomSliderHeader'
import CustomSliderVideoItem from './CustomSliderVideoItem'

import { observer } from "mobx-react-lite"

interface Props {
    items: string[]
    editMode?: boolean
    store: ActorStore
}
interface IItem {
    id: number
    src: string
}
enum TypeAddForm{
    photo,
    video
}

const CustomVideoSlider = ({ items = [], store, editMode = false }: Props) => {

    const [toDelete, setToDelete] = useState<IItem>({ id: 0, src: '' })
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

    return (
        <div>
            <ModalConfirmDeleteVideo
                id={toDelete.id}
                src={toDelete.src}
                showConfirmModal={showConfirmModal}
                setShowConfirmModal={setShowConfirmModal}
                items={items}
            />
            <div style={{ marginLeft: -10, marginRight: 10, userSelect: "none" }} >
                <CustomSliderHeader label="Видео" items={items} store={store} type={TypeAddForm.video}/>
                <Row>
                    {items.map((video, id) => <CustomSliderVideoItem key={id} id={id} video={video} setToDelete={setToDelete} setShowConfirmModal={setShowConfirmModal} />)}
                </Row>
            </div>
        </div>
    )
}

export default observer(CustomVideoSlider)