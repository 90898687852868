import React, { useContext, useEffect, useState } from "react"
import { Button, Container, Figure, Modal, OverlayTrigger, Pagination, Spinner, Stack, Table } from 'react-bootstrap'
import { Context } from "../index"
import { observer } from "mobx-react-lite"
import { useParams, Link, useHistory } from 'react-router-dom'
import { IUser } from '../models/IUser';

enum Roles {
    admin,
    moderator,
    unknown
}

const UsersPage = () => {
    const { page = '1' } = useParams<{ page: string }>()
    const { limit = '10' } = useParams<{ limit: string }>()
    const [show, setShow] = useState<boolean>(false)
    const [toDelete, setToDelete] = useState<string>('')
    const history = useHistory()
    const { userStore } = useContext(Context)

    useEffect(() => {
        userStore.fetchUsers(page, limit)
        document.title = "Пользователи"
    }, [page])

    if (userStore.isLoading) {
        return (
            <Container>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        )
    }
    const getFullName = (firstname?: string, lastname?: string, patronymic?: string) => {
        return [firstname, lastname, patronymic].join(' ')
    }

    const toPageHandler = (number: number) => {
        history.push(`/users/${number}/${limit}`)
    }
    const paginator = () => {
        let items = []
        let end = userStore.pages

        for (let number = 1; number <= end; number++) {
            items.push(
                <Pagination.Item disabled={userStore.count > ((number - 1) * parseInt(limit)) ? false : true} key={number} active={number === parseInt(page)} onClick={() => toPageHandler(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        return (
            <Pagination style={{ marginBottom: 0 }}>
                {items}
            </Pagination>
        )
    }

    const makeTr = (user: IUser, idx: number) => {
        return (
            <tr key={user._id} style={{ cursor: 'pointer' }}>

                <td>{parseInt(limit) * (parseInt(page) - 1) + idx + 1}</td>
                <td>
                    <Link to={`/user/${user._id}?page=${page}&limit=${limit}`}>
                        {getFullName(user.firstName, user.lastName)}
                    </Link>
                </td>
                <td style={{ textAlign: 'center' }}>
                    <Button variant="danger" onClick={() => { handleShow(user._id) }} disabled={user.role === Roles.admin}> Удалить </Button>
                </td>
            </tr >
        )
    }

    const deleteActor = async (id: string | undefined) => {
        if (id) {
            await userStore.delete(id)
            await userStore.fetchUsers(page, limit)
            console.log(userStore.users.length)
            if (userStore.users.length === 0) {
                let prevPage = parseInt(page) - 1
                history.push(`/users/${prevPage.toString()}/${limit}`)
            }
        }
    }
    const handleShow = (id: string | undefined) => {
        if (id) {
            setShow(true)
            setToDelete(id)
        }
    }

    const handleCloseReset = () => {
        setShow(false)
    }

    const handleCloseConfirm = async () => {
        await deleteActor(toDelete)
        setShow(false)

    }

    const confirmDelete = () => {
        return (
            <Modal show={show} onHide={handleCloseReset}>
                <Modal.Header closeButton>
                    <Modal.Title>Подтвердите удаление пользователя</Modal.Title>
                </Modal.Header>
                <Modal.Body>Вы хотите удалить пользователя?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReset}>
                        Отмена
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirm}>Удалить</Button>

                </Modal.Footer>
            </Modal>
        )
    }


    return (
        <Container>
            <Stack direction="horizontal" gap={3} style={{ marginBottom: 10 }}>
                {paginator()}
                <Button variant="success" onClick={(e) => history.push('/user')} className="ms-auto"> Добавить нового пользователя </Button>
            </Stack>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th colSpan={2}>ФИО <small>(нажмите на имя, для редактирования пользователя)</small></th>
                    </tr>
                </thead>
                <tbody>
                    {userStore.users.map((user, idx) => makeTr(user, idx))}
                </tbody>
            </Table>
            {confirmDelete()}
        </Container>
    )


}

export default observer(UsersPage)