import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import ActorStore from '../store/ActorStore';

interface ModalProps {
    id: number
    src: string
    store: ActorStore
    items: string[]
    showConfirmModal: boolean
    setShowConfirmModal: (value: React.SetStateAction<boolean>) => void
}
const ModalConfirmDeletePhoto = ({ id, src, store, items, showConfirmModal, setShowConfirmModal }: ModalProps) => {
    const deleteByIndex = (idx: number) => {
        items.splice(idx, 1)
    }
    const handleCloseConfirm = (id: number, src: string) => {
        deleteByIndex(id)
        store.deletePhoto(src)
        setShowConfirmModal(false)
    }

    return (
        <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Подтвердите удаление фото</Modal.Title>
            </Modal.Header>
            <Modal.Body>Вы хотите удалить фото безвозвратно?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                    Отмена
                </Button>
                <Button variant="danger" id={`${src}-${id}`} onClick={() => handleCloseConfirm(id, src)}>Удалить</Button>

            </Modal.Footer>
        </Modal>
    )
}

export default ModalConfirmDeletePhoto