import 'bootstrap/dist/css/bootstrap.min.css'

import { Context } from './index'
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { Container } from 'react-bootstrap'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Test from './pages/Test'
import ActorsPage from "./pages/ActorsPage"
import IndexPage from "./pages/IndexPage"
import ActorPage from "./pages/ActorPage"
import UsersPage from "./pages/UsersPage"
import UserPage from "./pages/UserPage"
import Login from "./pages/Login"
import Page404 from "./pages/Page404"
import NavbarComponent from "./components/NavbarComponent"
import ProtectedRoute from './components/ProtectedRoute'
import AdminRoute from './components/AdminRoute'
import Visistors from './pages/Visitors/Visitors'
import Visistor from './pages/Visitors/Visitor'

import { baseStore } from './store/BaseStore'
import CustomAlert from './components/CustomAlert';

enum Roles {
  admin,
  moderator,
  unknown
}

const App: FC = () => {

  const { authStore } = useContext(Context)

  useEffect(() => {
    if (localStorage.getItem('token')) {
      authStore.checkAuth()
    }
  }, [])

  if (authStore.isLoading) {
    return (
      <Router>
        <NavbarComponent />
        <Container>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>
      </Router>
    )
  }

  return (
    <Router>
      <div style={{ position: "relative", minHeight: '100vh', maxWidth: '100vw' }}>
        <NavbarComponent />
        <br />
        <Switch>
          <ProtectedRoute exact path="/" component={IndexPage} isAuth={authStore.isAuth} />
          <ProtectedRoute exact path="/visitors/:page?/:limit?" component={Visistors} isAuth={authStore.isAuth} />
          <ProtectedRoute exact path="/visitor/:id" component={Visistor} isAuth={authStore.isAuth} />
          <ProtectedRoute exact path="/" component={IndexPage} isAuth={authStore.isAuth} />
          <AdminRoute exact path="/users" component={UsersPage} isAdmin={authStore.role === Roles.admin} />
          <AdminRoute exact path="/user/:id?" component={UserPage} isAdmin={authStore.role === Roles.admin} />
          <AdminRoute exact path="/users/:page?/:limit?" component={UsersPage} isAdmin={authStore.role === Roles.admin} />
          <ProtectedRoute exact path="/actors/:page?/:limit?" component={ActorsPage} isAuth={authStore.isAuth} />
          <ProtectedRoute exact path="/actor/:id?" component={ActorPage} isAuth={authStore.isAuth} />
          <Route exact path="/login" component={Login} />
          <ProtectedRoute exact path="/profile" component={Test} isAuth={authStore.isAuth} />
          <Route component={Page404} />
        </Switch>
        <div style={{ position: 'absolute', bottom: 0, right: '15px' }}>
          {baseStore.errorList.map((error, idx) => <CustomAlert error={error} idx={idx} key={idx} />)}
        </div>
      </div>
    </Router>
  )
}

export default observer(App)
