

import React from 'react'
import { Col } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai'

interface IPhoto {
    id: number
    photo: string
}

interface ItemProps {
    photo: string
    id: number
    setToDelete: (value: React.SetStateAction<IPhoto>) => void
    setShowConfirmModal: (value: React.SetStateAction<boolean>) => void
    setShowFullSize: (value: React.SetStateAction<boolean>) => void
    setFullSizePhoto: (value: React.SetStateAction<IPhoto>) => void
}


const CustomSliderPhotoItem = ({ id, photo, setShowFullSize, setFullSizePhoto, setToDelete, setShowConfirmModal }: ItemProps) => {

    let isHidden = false
    if (id >= 3) {
        isHidden = true
    }


    const handleShow = (event: React.MouseEvent<SVGElement>) => {
        event.preventDefault()
        const [photo, id] = event.currentTarget.id.split('-')
        setToDelete({ id: parseInt(id), photo })
        setShowConfirmModal(true)
    }


    return (
        <Col md={4} xs={12} key={id} hidden={isHidden}>
            <div className="gallery" style={{ position: 'relative' }}>
                <AiFillCloseCircle id={`${photo}-${id}`} onClick={handleShow} style={{ position: "absolute", color: "red", fontSize: "25px", top: "-5px", right: "-26px", cursor: "pointer" }} />
                <img
                    src={photo}
                    style={{ cursor: "pointer", maxWidth: '100%', margin: 10, aspectRatio: "1" }}
                    onClick={() => { setShowFullSize(true); setFullSizePhoto({ id, photo }) }}
                />
            </div>
        </Col>
    )
}

export default CustomSliderPhotoItem