import React, { useState } from "react"
import { Badge, Button, Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { observer } from "mobx-react-lite"
import { AiFillCloseCircle } from "react-icons/ai";

interface ItemsProps {
    items: string[]
}

const Skills = ({ items }: ItemsProps) => {
    const [toAdd, setToAdd] = useState('')
    const colors = [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark',
    ]

    const renderBadges = () => {
        return items.map((item, id) => badgeBody(item, id))
    }

    const header = () => {
        return (
            <h3>Навыки</h3>
        )
    }

    const handleAdd = () => {
        if (toAdd.length > 0) {
            items.push(toAdd)
            setToAdd('')
        }
    }

    const addSkils = () => {
        return (
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Введите навык актера"
                    aria-label="Введите навык актера"
                    aria-describedby="skillAdd"
                    value={toAdd}
                    onChange={(val) => setToAdd(val.currentTarget.value)}
                />
                <Button disabled={toAdd.length === 0} variant="outline-secondary" id="skillAdd" onClick={() => handleAdd()}>
                    Добавить навык
                </Button>
            </InputGroup>
        )
    }


    const badgeBody = (item: string, id: number) => {
        const color = colors[id % colors.length]
        const textColor = (color === 'warning' || color === 'light') ? 'dark' : 'light'
        return (
            <Col xs={'auto'} md={'auto'} key={id}>
                <div className="gallery" style={{ position: 'relative' }}>
                    <AiFillCloseCircle
                        onClick={() => deleteByIndex(id)}
                        style={{ position: "absolute", color: "red", fontSize: "20px", top: "-12px", right: "-20px", cursor: "pointer" }}
                    />
                    <div style={{ cursor: "pointer", marginLeft: "10px", marginRight: "-10px" }}>
                        <h4>
                            <Badge bg={color} text={textColor}>
                                {item}
                            </Badge>
                        </h4>
                    </div>
                </div>
            </Col>
        )
    }

    const deleteByIndex = (id: number) => {
        items.splice(id, 1)
    }


    return (
        <>
            {header()}
            <Row md={12}>
                {renderBadges()}
            </Row>
            <br />
            {addSkils()}
        </>
    )
}

export default observer(Skills)